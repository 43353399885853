import React, { useState } from 'react';

import { TextField, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { Turnstile } from '@marsidev/react-turnstile'

export const SignUpForm = () => {
  const [membershipNumber, setMembershipNumber] = useState('');
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState(null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!captcha) {
      setError('Please check the CAPTCHA to continue');
      return;
    }

    setError(null);

    const data = {
      membershipNumber,
      email,
      captcha
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    console.log(data);

    fetch(process.env.REACT_APP_API_ENDPOINT+'pub/accountRequest', options)
      .then((response) => response.json())
      .then((result) => {
        if(result.success)
        {
          setSuccess(result);
        }
        else
        {
          setError('Please try again or raise a support request.');
        }
      })
      .catch((error) => {
        setError('Please try again or raise a support request.');
      });
  };

  return (
    <Container maxWidth="sm" >
      <h2 align="center">New Account Request</h2>

      <div style={{ display: success ? "none" : "block" }}>
        <p>Please complete the form below to request a Suffolk Scouts user account.</p>

        <p>This will request an Office 365 account, with a personal @suffolkscouts.org.uk email address. For shared mailboxes, distribution lists, and teams, please raise a support request.</p>

        <Alert severity="info">You must hold an active role within Suffolk (on the HQ Membership System) in order to complete this request.</Alert>
      </div>

      {success &&  <Alert sx={{mt:1}} severity="success"><AlertTitle>Request sucessfully submitted</AlertTitle><p>Please check your email and click on the link for further instructions.</p> <p><strong>Note:</strong> The link expires after 60 minutes, so be quick!</p></Alert>}

      <form onSubmit={handleSubmit} style={{ display: success ? "none" : "block" }}>

        {error &&  <Alert sx={{mt:1}} severity="error"><AlertTitle>Error</AlertTitle> There was an error submitting your request. {error}</Alert>}

        <Grid container spacing={3} sx={{ mt:3, mb:3 }}>
          <Grid item xs={12} sm={8} smOffset={2}>
            <TextField
              label="Membership Number"
              fullWidth={true}
              required={true}
              type="number"
              helperText="Your Scouts Membership Number, e.g. 12345678"
              value={membershipNumber}
              onChange={(event) => setMembershipNumber(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={8} smOffset={2}>
            <TextField
              label="Email Address"
              required={true} 
              fullWidth={true}
              type="email"
              value={email}
              helperText="Please use your email currently recorded on your HQ record. This will be used to verify your account and send your new account details."
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Turnstile siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY} options={{ theme: 'light' }} onSuccess={(token) => setCaptcha(token)} />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Button variant="contained" type="submit">Request Account</Button>
          </Grid>
          
        </Grid>
      </form>
      
    </Container>
  );
};