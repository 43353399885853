import React, { useState, useEffect } from "react";

import { UserContext } from "../userContext";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";

import { loginRequest } from "../authConfig";
import { fetchUserProfile } from "../graph";


import { Header } from "../components/Header";
import Footer  from "../components/Footer";

import { Outlet, useNavigate } from "react-router-dom";

export default function Root() {

    const [user, setUser] = useState(null);
    const { instance, accounts, inProgress } = useMsal();

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    // Update user details if logged in
    useEffect(() => {

        if (!isAuthenticated) {
            navigate("/login", { replace: true })
        }

        if (isAuthenticated && inProgress === InteractionStatus.None && accounts.length > 0) {
            if (user) {
                // Skip data refresh if already set - adjust logic for your specific use case
                return;
            }

            const tokenRequest = {
                ...loginRequest,
                account: accounts[0]
            };

            // Acquire an access token
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                // Call your API with the access token and return the data you need to save in state
                fetchUserProfile(response.accessToken).then((data) => {
                    setUser(data);
                    // setLoading(false);
                });
            }).catch(async (e) => {
                // Catch interaction_required errors and call interactive method to resolve
                if (e instanceof InteractionRequiredAuthError) {
                    await instance.acquireTokenRedirect(tokenRequest);
                }
                throw e;
            });
        }

    },[accounts, instance, inProgress, user, setUser, isAuthenticated, navigate])

    return (
        <UserContext.Provider value={user}>
            <Header />
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Outlet />
            </div>
            <Footer />
        </UserContext.Provider>
    );
}