import { createTheme } from '@mui/material/styles'

export const ScoutTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#490499',
    },
    secondary: {
      main: '#00b8a3',
    },
    success: {
      main: '#25b755',
    },
    error: {
      main: '#ed3f23',
    },
    info: {
      main: '#006ddf',
    },
    warning: {
      main: '#ffe627',
    },
  },
  typography: {
    fontFamily: 'Nunito Sans',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
    h1: {
      fontWeight: 900,
    },
    h2: {
      fontWeight: 900,
    },
    h3: {
      fontWeight: 900,
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 0,
  },
})