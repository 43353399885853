import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

// Roots
import Root from './routes/Root';
import RootPublic from './routes/RootPublic';
import Index from "./routes/index";

import { SignUpForm } from './routes/SignUpForm';
import ProcessAccount from './routes/ProcessAccount';

import ErrorPage from "./components/ErrorPage";

// Theming
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ScoutTheme } from './theme';
import './index.css';

// MS AzureAD Integration
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

// React Router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//Init MSAL
const msalInstance = new PublicClientApplication(msalConfig);

// Router setup
const router = createBrowserRouter([
  {
    path: "/login",
    element: <RootPublic />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Index /> },
      { path: "new-account", element: <SignUpForm /> },
      { path: "process-account/:token", element: <ProcessAccount /> }
    ]
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Index /> },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
   <ThemeProvider theme={ScoutTheme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <RouterProvider router={router} />
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
