import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function ProcessAccount() {

    const { token } = useParams()
    const [validationResult, setValidationResult] = useState(null);

    // Send the token back to the server for validation
    useEffect(() => {
        if (token) {
          // Send the token to the server for validation.
          fetch(process.env.REACT_APP_API_ENDPOINT+'pub/processAccountRequest', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          })
            .then((response) => response.json())
            .then((result) => {
              if(result.success) {
                setValidationResult(true);
              }
              else {
                setValidationResult(false);
              }
                
            });
        }
      }, [token]); // Only run the effect if the token changes.

      return (
        <Container maxWidth="sm">
        <h2 align="center">New Account Request</h2>

          {validationResult !== null && (
            <>
                {validationResult ? (
                    <Alert sx={{mt:1}} severity="success"><AlertTitle>Account Request Successful</AlertTitle><p>Your login details and instructions have been sent to your email address.</p></Alert> 
                ) : (
                    <Alert sx={{mt:1}} severity="error"><AlertTitle>Error</AlertTitle><p>There was an error processing your request.</p> <p>Please note the link is only valid for 60 minutes. If you continue to have issues please raise a support request.</p></Alert>
                )}
             </>
          )}
        </Container>
      );
}
