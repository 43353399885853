import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

import Container from '@mui/material/Container';

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export default function Index() {

    const { instance } = useMsal();

    return (
        <>
            <AuthenticatedTemplate>
                <Link to="/">
                    <Button key="Go To Portal" variant="contained">
                        Go to portal
                    </Button>
                </Link>
            </AuthenticatedTemplate>
            
            <UnauthenticatedTemplate>
                <Container maxWidth="sm" sx={{alignItems: 'center', textAlign: 'center'}}>
                    <p>Welcome to the Suffolk Scouts Members Portal</p>

                    <p>
                        <Button key="Login" variant="contained" sx={{ color: '#fff' }} onClick={() => handleLogin(instance)}>
                            Login via Office 365 &nbsp;<LoginIcon />
                        </Button>
                    </p>

                    <p>
                        <Link to="/login/new-account">
                            <Button key="request account" variant="outlined" color="secondary">
                                Request an account &nbsp;<PersonAddIcon />
                            </Button>
                        </Link>
                    </p>
                </Container>
            </UnauthenticatedTemplate>
        </> 
    );
  }
