import React from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

import { ScoutCrown } from "./graphics";
import {UserContext } from "../userContext";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Header = (props) => {
    const user = React.useContext(UserContext);

    const { instance } = useMsal();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <AppBar position="static" sx={{ boxShadow: 0 }}>
                <Toolbar variant="regular">
                    <ScoutCrown width="50" height="50" sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }} />                                            
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: { md: 'flex' },
                            ml: 1,
                            fontWeight: 800,
                            textDecoration: 'none',
                            letterSpacing: '-0.01rem',
                            color: "#fff",
                        }}
                    >
                        Suffolk Scouts
                    </Typography>

                    <Box sx={{ flexGrow: 1 }}></Box>

                    { user ?
                    <> 
                        <Box sx={{ flexGrow: 0, mr: 2, display: { xs: 'none', sm: 'block' }}}>
                                <Typography variant="subtitle1" align="right" fontWeight={600} lineHeight={0.75} mt={0.5}>
                                    {user.displayName}
                                </Typography>
                                <Typography variant="caption" align="right">
                                    {user.jobTitle}
                                </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={user.displayName} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                            <Menu
                                sx={{ mt: '49px', boxShadow: 0 }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <Box>
                                    <Typography textAlign="center">Hello, {user.displayName}!</Typography>
                                </Box>
                                <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">Profile</Typography>
                                </MenuItem>

                                <MenuItem key="Logout" onClick={() => handleLogout(instance)}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </>
                    : 
                    <>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button key="Login" sx={{ color: '#fff' }} onClick={() => handleLogin(instance)}>
                                Login &nbsp;<LoginIcon />
                            </Button>
                        </Box>
                    </> }
                </Toolbar>
            </AppBar>
        </>
    );
};