import React, { useEffect } from "react";

import {
  Box,
  Container,
  Paper
} from '@mui/material'

import { useIsAuthenticated } from "@azure/msal-react";
import { ScoutCrown } from "../components/graphics";
import Typography from '@mui/material/Typography';

import { Outlet, useNavigate } from "react-router-dom";

export default function RootPublic() {

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    // Update user details if logged in
    useEffect(() => {
        if (isAuthenticated) {
            navigate("/", { replace: true })
        }
    },[isAuthenticated, navigate])

    return (
        <Box component="main" className="public-container">
            <Container maxWidth="sm">
                <Paper elevation={8} sx={{ mt: 2, padding: 1, pb: 4 }}>
                    <Box sx={{ display: { xs: 'flex' }, width: '100%', alignItems: 'center', justifyContent: 'center', pt: 2 }} className="public-header">
                        <ScoutCrown width="50" height="50" sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }} />                                            
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                display: { md: 'flex' },
                                ml: 1,
                                fontWeight: 800,
                                textDecoration: 'none',
                                letterSpacing: '-0.01rem',
                                color: "#000",
                            }}
                        >
                            Suffolk Scouts
                        </Typography>
                    </Box>
                    <Outlet />
                </Paper>
            </Container>
        </Box>
    );
}